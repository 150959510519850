import api from "./api"

export const upload = ({ onProgress, file, filename }) => {
  return api.kaka.upload(
    {
      url: "file/upload",
      onUploadProgress: function (e) {
        if (e.total > 0) {
          e.percent = (e.loaded / e.total) * 100
        }
        onProgress(e)
      },
    },
    {
      file,
      filename,
    }
  )
}

export const ossUpload = async ({ onProgress, file, filename, type }) => {
  const ossToken = await api.kaka.query({
    url: "file/upload/sign",
    params: {
      type,
    },
  })
  const { accessid, policy, signature, dir, host, prefix } = ossToken
  const key = `${dir}${+new Date()}_${file.name}`
  await api.kaka.upload(
    {
      url: host,
      returnResponse: true,
      onUploadProgress: function (e) {
        if (e.total > 0) {
          e.percent = (e.loaded / e.total) * 100
        }
        onProgress(e)
      },
    },
    {
      file,
      filename,
      data: {
        key,
        policy,
        OSSAccessKeyId: accessid,
        signature,
      },
    }
  )
  const url = `${prefix}${key}`
  console.log(url)
  return url
}
