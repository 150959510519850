<template>
  <el-tabs stretch class="tab" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="文字" name="first">
      <!-- 上传部分 -->
      <!-- <div style="height: 200px">
        <el-form>
          <el-form-item>
            文字颜色：
            <el-input style="width: 150px" v-model="textColor" />
            <el-color-picker v-model="textColor" />
          </el-form-item>
          <el-form-item>
            背景颜色：
            <el-input style="width: 150px" v-model="backgroundColor" />
            <el-color-picker v-model="backgroundColor" />
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="uploadText">上传文字</el-button>
      </div> -->
      <!-- 展现出来的文字效果 -->
      <span class="text" v-for="(text, index) in texts" :key="index">
        <el-button
          class="btn"
          :style="{
            backgroundColor: text.backgroundColor,
          }"
        >
          <span
            :style="{
              color: text.textColor,
            }"
          >
            咔咔贴纸
          </span>
        </el-button>
      </span>
      <span><el-button @click="text = true">点击上传</el-button></span>
      <!-- 底部上传部分 -->
      <el-drawer title="文字上传" :visible.sync="text" direction="btt">
        <el-form>
          <el-form-item>
            文字颜色：
            <el-input style="width: 150px" v-model="textColor" />
            <el-color-picker v-model="textColor" />
          </el-form-item>
          <el-form-item>
            背景颜色：
            <el-input style="width: 150px" v-model="backgroundColor" />
            <el-color-picker v-model="backgroundColor" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="uploadText">上传文字</el-button>
          </el-form-item>
        </el-form>
      </el-drawer>
    </el-tab-pane>
    <el-tab-pane label="相框" name="second">
      <upload v-model="pic" />
      <el-button type="primary" @click="uploadPic">上传相框</el-button>
      <div class="pic" v-for="(img, index) in pictures" :key="index">
        <img :src="img.link" alt="" />
      </div>
    </el-tab-pane>
    <el-tab-pane label="贴纸分类" name="third">
      <upload v-model="fram" />
      <el-button type="primary" @click="uploadFra">上传贴纸</el-button>
      <div class="fra" v-for="(img, index) in frames" :key="index">
        <img :src="img.link" alt="" />
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import upload from "./components/FileUploader.vue";
export default {
  name: "App",
  components: {
    upload,
  },
  data() {
    return {
      // 默认选择第一个
      activeName: "first",
      texts: [],
      pictures: [],
      frames: [],

      // 显示与隐藏
      text: null, // 文字上传

      // 携带参数
      textColor: "",
      backgroundColor: "",
      pic: "",
      fram: "",
    };
  },
  methods: {
    handleClick() {
      this.getAll();
      this.textColor = "";
      this.backgroundColor = "";
      this.pic = "";
      this.fram = "";
    },
    async getAll() {
      const { data: res } = await this.$axios.get("/api/sticker/all");
      if (res.status.code !== 0) return this.$message.error(res.msg);
      // this.$message.success("获取数据成功");
      this.texts = res.data.texts;
      this.pictures = res.data.pictures;
      this.frames = res.data.frames;
    },
    // 上传文字
    async uploadText() {
      const { data: res } = await this.$axios.post("/api/sticker/text", {
        textColor: this.textColor,
        backgroundColor: this.backgroundColor,
      });
      if (res.status.code !== 0) return this.$message.error(res.msg);
      this.$message.success("上传成功");
      this.getAll();
    },

    // 相框上传
    async uploadPic() {
      const { data: res } = await this.$axios.post("/api/sticker/picture", {
        link: this.pic,
      });
      if (res.status.code !== 0) return this.$message.error(res.msg);
      this.$message.success("上传相框成功");
    },

    // 贴纸上传
    async uploadFra() {
      const { data: res } = await this.$axios.post("/api/sticker/frame", {
        link: this.fram,
      });
      if (res.status.code !== 0) return this.$message.error(res.msg);
      this.$message.success("上传贴纸成功");
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>

<style scoped>
img {
  height: 100%;
  width: 100%;
}
.tab {
  width: 600px;
  height: 100vh;
  margin: 0 auto;
  border-radius: 25px;
  border: 1px solid #ccc;
}
/* 文字 */
.text {
  margin: 15px;
}
.btn {
  margin-top: 15px;
}
/* 相框 */
.pic {
  width: 200px;
  height: 100px;
}
/* 框架 */
.fra {
  width: 200px;
  height: 100px;
}
</style>
