import API from "@/scripts/api" // 后台接口

export const kaka = new API({
  successCode: 0,
  baseURL: "http://kakacamera.ydksmob.com",
  // baseURL: "http://192.168.0.161:50301",
  appendData: (config) => {
    config.params._t = +new Date()
  },
})

const list = {
  kaka,
}

export default {
  setHeader (header) {
    for (const key of Object.keys(list)) {
      list[key].setHeader(header)
    }
  },
  setLogoutHandler (handler) {
    for (const key of Object.keys(list)) {
      list[key].setLogoutHandler(handler)
    }
  },
  ...list,
}
