export default {
  API: {
    KAKA: {
      ROOT: "/api",
      ROOT_DEV: "/api",
    },
  },
  STORAGE_PREFIX: "nx_",
  DATE: {
    HM: "HH:mm",
    HMS: "HH:mm:ss",
    FULL_DATE_TIME: "YYYY-MM-DD HH:mm:ss",
    SIMPLE_DATE: "YYYY-MM-DD",
    SIMPLE_MONTH: "YYYY-MM",
    SIMPLE_YEAR: "YYYY",
  },
}
