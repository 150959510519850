import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import axios from "axios";

Vue.config.productionTip = false;

Vue.prototype.$axios = axios.create({
  // mineAxios
  // baseURL: "http://192.168.0.161:50301/",
  baseURL: "http://kakacamera.ydksmob.com/",
  responseType: "json",
});
// import "@/styles/main.scss";
Vue.use(ElementUI);

new Vue({
  el: "#app",
  render: (h) => h(App),
});

// new Vue({
//   render: (h) => h(App),
// }).$mount("#app");
