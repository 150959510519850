<template>
  <el-upload
    action=""
    :http-request="httpRequest"
    :on-success="onSuccess"
    :on-error="onError"
    :on-change="onChange"
    :show-file-list="false"
    :multiple="false"
  >
    <slot>
      <el-input v-if="value" :value="value" readonly>
        <template slot="append"> 更改 </template>
      </el-input>
      <el-button v-else> 选择文件 </el-button>
    </slot>
  </el-upload>
</template>
<script>
import * as commonApi from "@/api/common";
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },

  computed: {},

  methods: {
    async httpRequest(options) {
      this.$emit("upload", options);
      return await commonApi.ossUpload({
        onProgress: options.onProgress,
        file: options.file,
        filename: options.filename,
        type: this.type,
      });
    },

    onSuccess(response) {
      this.$emit("input", response);
    },

    onError(err) {
      console.log(err);
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>
